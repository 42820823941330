import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import logo from "assets/paluz-logo.png";
import clienteAxios from "../../config/clienteAxios";
import { useKonamiCode } from "components/SecretCode/useKonamiCode";
import axios from "axios";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {" © "}
      {new Date().getFullYear()}
      {" - "}
      <Link color="inherit" href="https://paluz.org/">
        PALUZ
      </Link>
    </Typography>
  );
}

const theme = createTheme();

export default function Login() {
  const [correo, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alerta, setAlerta] = useState(false);

  const navigate = useNavigate();

  //Variable para Llamar Función del Easter Egg
  const konami = useKonamiCode();

  const nav = useNavigate();

  if (konami) {
    nav("/secret");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if ([correo, password].includes("")) {
      setAlerta({
        msg: "Todos los campos son obligatorios",
        error: true,
      });
      return;
    }

    try {
      const { data } = await axios
        .post("/login/usuario/", {
          email: correo,
          password: password,
        })
        .then(function (response) {
          setAlerta({});
          console.log(response.data.idToken);
          localStorage.setItem("token", JSON.stringify(response.data));
          //setAuth(data
          navigate("/inicio");
        })
        .catch(function (error) {
          console.log(error);
          setAlerta(true);
          setTimeout(function () {
            setAlerta(false);
          }, 3000);
          setAlerta(false);
        });

      setAlerta({});
      console.log(data);
    } catch (error) {
      setAlerta({
        msg: "errr",
        error: true,
      });
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleLinkClick = (event, message) => {
    if (message === "registro") {
      nav("/registro");
    } else {
      nav("/resetPass");
    }
  };

  const handleLinkPost = (event, message) => {
    if (message === "post") {
      nav("/postulacion");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          position: "relative",
          marginTop: 0.6,
          marginRight: 5,
          zIndex: 999,
        }}
      >
        {alerta ? (
          <Alert
            variant="filled"
            severity="error"
            sx={{
              position: "fixed",
              top: 2,
              right: 3,
              zIndex: 999,
              borderRadius: 2,
              boxShadow: 4,
            }}
          >
            ¡Usuario o contraseña incorrectos!
          </Alert>
        ) : (
          ""
        )}
        {/*<Alert variant="filled" severity="warning" sx={{ position:'fixed', top: 2, right: 3, zIndex:999, borderRadius:2, boxShadow:4 }}>
        ¡Tu cuenta está inactiva, comunícate con el administrador del sistema!
    </Alert> */}
      </div>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={false}
          md={7}
          sx={{
            backgroundImage:
              "url(https://iili.io/2io3klf.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              display="flex"
              component="img"
              alt="logo"
              src={logo}
              height="60px"
              width="200px"
            ></Box>
            <Typography className="mt-4" component="h1" variant="h5">
              Ingresa a tu Cuenta
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Correo Electrónico"
                value={correo}
                name="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Cambiar visibilidad de la contraseña"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Recordarme"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, bgcolor: "teal" }}
                disabled={!correo || !password}
              >
                Iniciar Sesión
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link
                    variant="body2"
                    sx={{ cursor: "pointer" }}
                    onClick={(event) => handleLinkClick(event, "reset")}
                  >
                    ¿Olvidaste tu contraseña?
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    variant="body2"
                    sx={{ cursor: "pointer" }}
                    onClick={(event) => handleLinkClick(event, "registro")}
                  >
                    ¿No tienes cuenta? Regístrate
                  </Link>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={5}>
                  <Button
                    size="large"
                    variant="contained"
                    sx={{ mt: 5, mb: 2, bgcolor:'teal', color:'white'}}
                    color="success"
                    fullWidth
                    onClick={(event) => handleLinkPost(event, "post")}
                  >
                   ¡Postúlate al voluntariado!
                  </Button>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
